import React from "react";

import Heading from "$ui/heading";
import WhiteBox from "$ui/whiteBox";

import heroImg from "$images/about/hero.png";

const WhoAreWe = () => (
  <WhiteBox>
    <Heading>Who we are?</Heading>
    <p className="text-center mt-2 phone:w-3/4 phone:mx-auto">
      &mdash; Welcome to India’s leading consumer rights organisation
    </p>

    <div className="flex items-center justify-center">
      <img src={heroImg} alt="HERO" className=" h-64 w-64" />
    </div>

    <article className="mt-10 space-y-10 leading-relaxed phone:text-center">
      <p>
        cro is an online consumer complaint platform in India that helps
        consumers resolve their complaints against companies quickly and
        effectively. The platform allows consumers to file complaints against
        companies and provides a hassle-free resolution process that includes
        escalation of the complaint to higher authorities, legal notice
        drafting, and even filing a consumer court case if required
      </p>
      <p>
        OUR VISION: A new India in which every consumer keeps thorough knowledge
        of his rights and their correct uses.
      </p>
      <p>
        OUR MISSION: - To organize, educate and make consumers aware of their rights, make the best effort to stop unfair trade
practices and solve consumer's problem/complaints.

      </p>
      <a href="/about" className="text-blue-500 cursor-pointer  py-4">
        read more
      </a>
    </article>
  </WhiteBox>
);

export default WhoAreWe;
