import React from "react";

import Heading from "$ui/heading";
import Anchor from "$ui/anchor";

const ComplaintType = ({ title, description, image }) => (
  <div className="relative bg-gray-100 p-5 space-y-5 bg-complaint shadow-2xl rounded-lg">
    <div className="h-48 bg-gray-300 bg-opacity-70 rounded-lg  flex justify-center items-center">
      <img
        src={image}
        alt="COMPLAINT TYPE"
        className="h-20 w-20 object-cover "
      />
    </div>

    <Heading variant="tertiary">{title}</Heading>

    <p>{description}</p>
  </div>
);

ComplaintType.defaultProps = {
  title: "",
  description: "",
};

export default ComplaintType;
