import React from "react";
import { Helmet } from "react-helmet";

import WhoAreWe from "$components/whoAreWe";
import Counter from "$components/counter";
import Service from "$components/service";
import Slider from "$components/slider";
import Testimonial from "$components/testimonial";
import ComplaintType from "$components/complaints/complaintType";

import { useUser } from "$hooks/auth";

import Heading from "$ui/heading";
import Anchor from "$ui/anchor";
import Section from "$ui/section";

import testImg from "$images/complaint/hero.png";
import male from "$images/complaint/bussiness.png";
import slide1Img from "$images/home/slide-1.png";
import slide2Img from "$images/home/slide-2.png";
import slide3Img from "$images/home/slide-3.png";
import slide4Img from "$images/home/slide-4.png";
import slide5Img from "$images/home/slide-5.png";
import Trust from "$images/icons/trust.png";
import Advo from "$images/icons/advo.png";
import Sol from "$images/icons/solution.png";
import Saf from "$images/icons/safe.png";

import medicalImg from "$images/typeComplaintIcons/healthcare.png";
import estateImg from "$images/typeComplaintIcons/building.png";
import airline from "$images/typeComplaintIcons/airline.png";
import railway from "$images/typeComplaintIcons/railway.png";
import banking from "$images/typeComplaintIcons/banking.png";
import postal from "$images/typeComplaintIcons/postal.png";
import travel from "$images/typeComplaintIcons/travel.png";
import telecom from "$images/typeComplaintIcons/telecom.png";
import gov from "$images/typeComplaintIcons/gov.png";
import ecommerce from "$images/typeComplaintIcons/ecommerce.png";
import homeApp from "$images/typeComplaintIcons/home-appliances.png";
import electricity from "$images/typeComplaintIcons/ele.png";
// import government from "$images/typeComplaintIcons/government.png";
import lpg from "$images/typeComplaintIcons/gas-station.png";
import food from "$images/typeComplaintIcons/food.png";
import mobile from "$images/typeComplaintIcons/mobile.png";
import car from "$images/typeComplaintIcons/automobiles.png";
import edu from "$images/typeComplaintIcons/education.png";
import d2h from "$images/typeComplaintIcons/d2h.png";
import hotelImg from "$images/typeComplaintIcons/hotel.png";
import insuranceImg from "$images/typeComplaintIcons/family-insurance.png";
import onlineImg from "$images/typeComplaintIcons/internet-security.png";
import packagingImg from "$images/typeComplaintIcons/fruits.png";
import transporationImg from "$images/typeComplaintIcons/container.png";
import otherImg from "$images/typeComplaintIcons/conversation.png";

const IndexPage = () => {
  const { user } = useUser();

  return (
    <>
      <Helmet>
        <title>TheCRO &mdash; Home</title>
      </Helmet>

      <header className="min-h-screen bg-hero grid gap-y-20 grid-cols-2 items-center px-20 py-10 tab-port:bg-none tab-port:grid-cols-none tab-port:pt-32 phone:px-6">
        <div className="space-y-8 tab-port:text-center">
          <Heading variant="primary" className="font-extrabold">
            Welcome to India's leading <br />
            Consumer Rights Organization
            <span className="text-primary block">CRO</span>
          </Heading>
          <p>
            An Indian online consumer portal by india's leading consumer rights
            organization - CRO (Upbhokta Adhikar Sangathan)
          </p>

          <div className="flex space-x-5 tab-port:justify-center">
            {user?.role !== "member" ? (
              <Anchor href="/membership" variant="filled">
                Become a member
              </Anchor>
            ) : (
              <Anchor href="/me/dashboard" variant="filled">
                View dashboard
              </Anchor>
            )}
            <Anchor href="/complaint" variant="outlined-colored">
              File a complaint
            </Anchor>
          </div>
        </div>

        <div>
          <Slider
            height="h-[50vh]"
            className="w-full bg-primary-light bg-opacity-75 rounded-xl"
          >
            <img
              src={slide1Img}
              alt="VECTOR"
              className="h-full w-full object-contain"
            />
            <img
              src={slide2Img}
              alt="VECTOR"
              className="h-full w-full object-contain"
            />
            <img
              src={slide3Img}
              alt="VECTOR"
              className="h-full w-full object-contain"
            />
            <img
              src={slide4Img}
              alt="VECTOR"
              className="h-full w-full object-contain"
            />
            <img
              src={slide5Img}
              alt="VECTOR"
              className="h-full w-full object-contain"
            />
          </Slider>
        </div>
      </header>

      <main>
        <Section withAbstract={true}>
          <WhoAreWe />
        </Section>

        <Section>
          <Heading>Our <span className=" text-blue-500">Strengths</span> </Heading>

          <div className="grid grid-cols-4 gap-x-20 gap-y-5 mt-16 phone:grid-cols-none">
            <Counter num={27} title="states" />
            <Counter num={1000} title="complaints" />
            <Counter num={3000} title="members" />
            <Counter num={1000} title="activities" />
          </div>
        </Section>

        <Section withAbstract={true} className="tab-port:bg-primary">
          <Heading className="tab-port:text-white">What we do</Heading>

          <div className="grid grid-cols-4 gap-x-6 gap-y-10 mt-20 tab-land:gap-x-16 tab-port:grid-cols-none tab-port:justify-center">
            <div className=" bg-white shadow-2xl rounded-lg hover:scale-105 transition-all duration-300 cursor-pointer">
              <p className=" italic font-bold text-center text-gray-700 p-4 ">
                Consumer often faces difficulty in asserting their rights
              </p>
              <img className=" h-40 my-2 mx-auto" src={Trust} />
              <h3 className="text-center font-extrabold text-blue-500 text-3xl">Trust</h3>
              <p className=" font-medium text-sm text-center text-gray-700 p-4 ">
                Lack of Transparency and accountability has impacted our customers
                confidence
              </p>
            </div>
               <div className=" bg-white shadow-2xl rounded-lg hover:scale-105 transition-all duration-300 cursor-pointer">
              <p className=" italic font-bold text-center text-gray-700 p-4 ">
                Empower the customers to assert their rights with ease and transparency
              </p>
              <img className=" h-40 my-2 mx-auto" src={Advo} />
              <h3 className="text-center font-extrabold text-blue-500 text-3xl">Advocacy</h3>
              <p className=" font-medium text-sm text-center text-gray-700 p-4 ">
                We are committed to promoting transparency, accountability and fairness to the marketplace
              </p>
            </div>
               <div className=" bg-white shadow-2xl rounded-lg hover:scale-105 transition-all duration-300 cursor-pointer">
              <p className=" italic font-bold text-center text-gray-700 p-4 ">
                One stop solution for all consumer compalints
              </p>
              <img className=" h-40 my-2 mx-auto" src={Sol} />
              <h3 className="text-center font-extrabold text-blue-500 text-3xl">One Stop Solution</h3>
              <p className=" font-medium text-sm  text-center text-gray-700 p-4 ">
                Simple and intuitive platform which connects consumers with resources that they need to assert their rights and get results
              </p>
            </div>
               <div className=" bg-white shadow-2xl rounded-lg hover:scale-105 transition-all duration-300 cursor-pointer">
              <p className=" italic font-bold text-center text-gray-700 p-4 ">
                Advanced data analytics for proactive support
              </p>
              <img className=" h-40 my-2 mx-auto" src={Saf} />
              <h3 className="text-center font-extrabold text-blue-500 text-3xl">Secure and Safe</h3>
              <p className=" font-medium text-sm text-center text-gray-700 p-4 ">
                Easy filing of complaints, Expert support real time tracking, and a database of result cases
              </p>
            </div>

          </div>
          
        </Section>

        <Section
          withoutPadding={true}
          className="pt-12"
          id="section-testimonials"
        >
          <Heading className="mb-12">Testimonials</Heading>

          <Slider height="h-[20rem] phone:h-[30rem]">
            <Testimonial
              image={male}
              userName="amber jain"
              review="I would like to thank you both for your cooperation, we'd really like to work with you again"
            />

            <Testimonial
              image={male}
              userName="arun mishra"
              review="CRO relieved me of frustration of dealing with uncooperative company. CRO helped me to solve the matters."
            />
          </Slider>
        </Section>

        <Section>
          <Heading>
            Know your <span className=" text-blue-500">rights</span> , Raise your <span className=" text-blue-500"> voice </span>. We are <span className=" text-blue-500"> with you!</span>
          </Heading>

          <div className="grid grid-cols-4 gap-x-10 gap-y-16 mt-8 tab-land:gap-x-16 tab-port:gap-x-5 phone:grid-cols-none">
            <ComplaintType image={airline} />

            <ComplaintType image={railway} />

            <ComplaintType image={banking} />
            <ComplaintType image={postal} />
            <ComplaintType image={medicalImg} />
            <ComplaintType image={travel} />
            <ComplaintType image={telecom} />
            <ComplaintType image={ecommerce} />
            <ComplaintType image={insuranceImg} />
            <ComplaintType image={estateImg} />
            <ComplaintType image={homeApp} />
            <ComplaintType image={electricity} />
            <ComplaintType image={gov} />
            <ComplaintType image={lpg} />
            <ComplaintType image={food} />
            <ComplaintType image={mobile} />
            <ComplaintType image={car} />
            <ComplaintType image={edu} />
            <ComplaintType image={onlineImg} />
            <ComplaintType image={d2h} />
            {/* <ComplaintType
              title="Airline"
              description="have you ever cheated on medical sector , hospital ,medical equipments and all other type of medical compalints"
              image={medicalImg}
            />
            <ComplaintType
              title="Medical"
              description="have you ever cheated on medical sector , hospital ,medical equipments and all other type of medical compalints"
              image={medicalImg}
            />
            <ComplaintType
              title="Insurance"
              description="Have you even been cheated in Life, Medical, Vehicle, Theft, Gadget and Others Insurance Service "
              image={insuranceImg}
            />
            <ComplaintType
              title="Real estate"
              description="Have you even been cheated in Developers, Housing Societies, Rent / Leased Property and more also other "
              image={estateImg}
            />
            <ComplaintType
              title="online"
              description="cheated in Online Shopping, Transaction, Quality and also other type of complaints"
              image={onlineImg}
            />
            <ComplaintType
              title="HOTEL / RESTAURANT"
              description="Have you even been cheated in Food Quality, Hygiene, Service Charges, MRP and Others.."
              image={hotelImg}
            />
            <ComplaintType
              title="transporation"
              description="Have you even been cheated in Product Safety, Packaging, Missing Product, Charges and others.."
              image={transporationImg}
            />
            <ComplaintType
              title="Packaging food"
              description="Have you even been cheated in Food Quality, MRP, Expiry, Packing Quality, Storage and Others.."
              image={packagingImg}
            />
            <ComplaintType
              title="other"
              description="we also cover other type of complaints too"
              image={otherImg}
            /> */}
          </div>
        </Section>
      </main>
    </>
  );
};

export default IndexPage;
