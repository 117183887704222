import React from 'react';

import Heading from '$ui/heading';

const Testimonial = ({ image, userName, review }) => (
  <figure className='relative flex flex-col items-center justify-between h-full bg-gray-100 p-10 phone:px-6'>
    <span className='absolute text-9xl text-gray-400 left-32 top-10 font-serif tab-port:left-16 phone:left-8'>
      “
    </span>
    <img
      src={image}
      alt='USER'
      className='h-32 w-32 object-cover rounded-full bg-primary'
    />
    <Heading variant='tertiary'>{userName}</Heading>
    <p className='w-2/3 text-center tab-port:w-4/5 phone:w-full'>
      &mdash; {review}
    </p>
  </figure>
);

export default Testimonial;
