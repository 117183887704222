import React, { useEffect, useState, useRef } from 'react';

import Heading from '$ui/heading';

const Counter = ({ num, title }) => {
  const [counter, setcounter] = useState(0);
  const itemRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target);
      }
    });
  });
  observer.observe(itemRef.current);
  return () => {
    if (itemRef.current) {
      observer.unobserve(itemRef.current);
    }
  };
  }, []);

  useEffect(() => {
    if ( isVisible && counter < num ) { 
      const timer = setTimeout(() => {
        setcounter(counter + 1);
      }, 0.1);

      return () => clearTimeout(timer)
    }
  }, [counter, isVisible]);
  return (
    <div ref={itemRef} className='text-center p-5 divide-y-2 divide-gray-300'>
      <Heading variant='primary' className='py-3'>
        {counter}+
      </Heading>
      <Heading variant='tertiary' className='py-3'>
        {title}
      </Heading>
    </div>
  );
}
export default Counter;
